export enum QueryKeys {
  language = 'language',
  qrCode = 'qrCode',
  store = 'store',
  product = 'product',
  compareProduct = 'compareProduct',
  productComments = 'productComments',
  compareProducts = 'compareProducts',
  buttonAnimationContent = 'buttonAnimationContent',
  searchCriteria = 'searchCriteria',
  searchCriteriaValue = 'searchCriteriaValue',
  productfinder = 'productfinder',
  imageTargetsCodes = 'imageTargetsCodes',
  homePageContent = 'homePageContent'
};

export enum DataTypes {
  beardStyle = 'beardStyle',
  blockContent = 'blockContent',
  brand = 'brand',
  button = 'button',
  category = 'category',
  categoryconsentLink = 'categoryconsentLink',
  criteriaValue = 'criteriaValue',
  inlineImage = 'inlineImage',
  supportLanguage = 'supportLanguage',
  palette = 'palette',
  product = 'product',
  productCriteria = 'productCriteria',
  qrCode = 'qrCode',
  review = 'review',
  searchCriteria = 'searchCriteria',
  translation = 'translation',
  localeBlockContent = 'localeBlockContent',
  localeJSON = 'localeJSON',
  localeString = 'localeString',
  localeText = 'localeText',
  sanityImageAsset = 'sanity.imageAsset',
  sanityFileAsset = 'sanity.fileAsset',
  homePageContent = 'homePageContent',
}

export const modelRef = 'ar-model';
